<script>
export default {
  name: 'ReadOnlyField',
  props: ['label','value', 'cls'],
  computed: {
    colClass() {
      return this.cls || 'col-12 col-sm-6'
    }
  }
}
</script>

<template>
<div :class="colClass" class="p-2">
  <div class="label">
    <label class="text-muted">{{label}}</label>
  </div>
  <div class="field">
    <div class=""><b>{{value}}</b></div>
  </div>
</div>
</template>
