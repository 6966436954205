<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'NewsView',
  components: {
    TablePage
  },
  data() {
    return {
      
    }
  },
  async created() {

  },
  methods: {
  }
}
</script>

<template>
<TablePage
  title="T-Shirts"
  data-key="tshirts"
  :canAdd="false"
  :canRead="false"
/>
</template>
