import { API } from '@/utils'

export default {
  namespaced: true,
  state: {
    tables: {}
  },
  mutations: {
    clearTables: (state) => {
      state.tables = {}
    },
    table: (state, tbl) => {
      state.tables[tbl.key] = tbl
    }
  },
  actions: {
    loadTable: async (store, data) => {
      const jwt = store.rootState.auth.jwt
      try {
        const req = await API(jwt).get('/table', {
          params: data
        })
        if (req.data && req.data.success) {
          store.commit('table', req.data)
          return req.data
        }
      } catch (err) {
        console.log('loadTable error', err)
      }
      return null
    },
    loadOne: async (store, data) => {
      const jwt = store.rootState.auth.jwt
      try {
        const req = await API(jwt).get('/one', {
          params: data
        })
        if (req.data && req.data.success) {
          // store.commit('table', req.data)
          return req.data
        }
      } catch (err) {
        console.log('load one error', err)
      }
      return null
    },
    saveOne: async (store, data) => {
      const jwt = store.rootState.auth.jwt
      try {
        const req = await API(jwt).post('/one', data)
        if (req.data) {
          return req.data
        }
      } catch (err) {
        console.log('save one error', err)
      }
      return { success: false, error: 'CONNECTION_ERROR' }
    },
    deleteOne: async (store, data) => {
      const jwt = store.rootState.auth.jwt
      try {
        const req = await API(jwt).post('/delone', data)
        if (req.data) {
          return req.data
        }
      } catch (err) {
        console.log('delete one error', err)
      }
      return { success: false, error: 'CONNECTION_ERROR' }
    }
  }
}
