<script>
export default {
  name: 'TopBar',
  computed: {
    userRole() {
      let res = ''
      if (this.$isAdmin) res = 'admin'
      if (this.$isRoot) res = 'super admin'
      return res
    }
  }
}
</script>

<template>
<nav class="navbar navbar-expand-lg bg-white border-light fixed-top">
  <div class="container-fluid ps-4 pe-5">
    <router-link :to="{ name: 'home' }" class="navbar-brand">
      <img id="logo" src="/logo.png"/>
    </router-link>
    <div v-if="$isAdmin || $isRoot" class="text-end d-none d-md-block">
      <div class="d-block">{{$store.state.auth.profile.email}}</div>
      <div class="d-block">
        <small class="text-muted">{{userRole}}</small>
      </div>
    </div>
    <div v-if="$isAdmin || $isRoot" class="text-end d-block d-md-none">
      <button @click="$store.commit('ui/togglemm')" class="btn-link btn">
        <img src="/mmenu.svg"/>
      </button>
    </div>
  </div>
</nav>
</template>

<style>
#logo {
  height: 24px;
}
.navbar {
  height: 70px;
}
</style>
