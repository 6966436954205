<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import UploadImageField from '@/components/fields/UploadImageField'
import DropdownField from '@/components/fields/DropdownField'
import Decimal from 'decimal.js'

export default {
  name: 'TokenForm',
  components: {
    FormPage,
    DropdownField,
    UploadImageField,
    TextField
  },
  data() {
    return {
      isNew: true,
      tshirts: [],
      formFields: {
        meta_name: {
          type: 'text',
          label: 'Meta Name',
        },
        meta_ticker: {
          type: 'text',
          label: 'Meta Ticker'
        },
        native_name: {
          type: 'text',
          label: 'Native Name'
        },
        native_ticker: {
          type: 'text',
          label: 'Native Ticker'
        },
        native_rate: {
          type: 'text',
          label: 'Native Rate'
        },
        virtual_rate: {
          type: 'integer',
          label: 'Auto-Exchange At Balance',
          help: 'Virtual Tokens will be automatically exchanged to another virtual or meta token when balance will reach this number. Use 0 to disable auto-exchange.'
        },
        virtual_ticker_rate: {
          type: 'integer',
          label: 'Auto-Exchange To Tokens',
          help: 'Number of exchanged tokens will be recieved after exchange. Use 0 to disable auto-exchange.'
        },
        virtual_ticker: {
          type: 'text',
          label: 'Auto-Exchange To Token',
          list: []
        },
        total_limit: {
          type: 'number',
          label: 'Total Limit',
          help: 'Max possible tokens in game at same time. Use -1 for unlimited and 0 to disable spawning'
        },
        user_limit: {
          type: 'number',
          label: 'Player Limit',
          help: 'Max possible tokens player can collect at moment. Use -1 for unlimited and 0 to disable collecting'
        },
        renew_hours: {
          type: 'number',
          label: 'Player Renew Hours',
          help: 'How many hours token need to wait before restore user limit for collected token if token is limited'
        },
        renew_hours_total: {
          type: 'number',
          label: 'Total Renew Hours',
          help: 'How many hours token need to wait before restore global limit for collected token if token is limited. If there is not global limit - do nothing.'
        },
        spawn_range: {
          type: 'number',
          label: 'Minimal spawn R',
          help: 'Minimal ditance in meters to spawn token. Use 0 for unlimited'
        },
        spawn_range_max: {
          type: 'number',
          label: 'Maximum spawn R',
          help: 'Maximum ditance in meters to spawn token. Use 0 for unlimited (not recommended)'
        },
        private_r: {
          type: 'number',
          label: 'Private spawn R',
          help: 'Percent of ditance in excluded range (0,1) (any other disables "private zone" option) between min and max to define as "Private Zone" (completelly random spawn positions in area)'
        },
        private_v: {
          type: 'number',
          label: 'Private spawn Volume (%)',
          help: 'Percent of available tokens to spawn in Private area (can be any, ofc, but logically - 0 to 100)'
        },
        private_expand: {
          type: 'boolean',
          label: 'Expand Private R',
          help: '(Global option) If NOT expanded - area of random spawn is Private R calculated raduis (villa scenario). If expanded - uses nearest token distance as Private R or max R if no waypoints found in max radius area (tibet scenario)'
        },
        with_icon: {
          type: 'image',
          label: 'Icon',
          help: 'Upload unique token icon to display where it is possible',
          table: 'tokens',
          field: 'with_icon',
          path: 'tokens'
        },
      },
      formValue: {
        meta_name: '',
        meta_ticker: '',
        native_name: '',
        native_ticker: '',
        native_rate: 0,
        virtual_rate: 0,
        virtual_ticker: '',
        virtual_ticker_rate: 0,
        total_limit: 0,
        user_limit: 0,
        renew_hours: 0,
        renew_hours_total: 0,
        private_r: 0,
        private_expand: false,
        is_virtual: true,
        enabled_tshirts: []
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New Virtual Token' : 'Edit Token'
    },
    isVirtual() {
      return !!this.formValue.is_virtual
    },
    tshirtColSize() {
      const sz = 12/this.tshirts.length
      if (sz === parseInt(sz)) return sz
      if (sz < 1) return 1
      if (sz < 2) return 1
      if (sz < 3) return 2
      if (sz < 4) return 3
      return sz
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const tokens = await this.$store.dispatch('tables/loadTable', {
      key: 'tokens',
      start: 0
    })
    const tshirts = await this.$store.dispatch('tables/loadTable', {
      key: 'tshirts',
      start: 0
    })
    if (!tokens.success || !tshirts.success) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.tshirts = tshirts.data
    this.formFields.virtual_ticker.list = tokens.data.map(el => ({key: el.token_id, label: el.meta_name}))
    if (this.$route.name === 'tokenAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const tid = this.$route.params.tid
    const el = (tokens.data || []).find(x => `${x.token_id}` === `${tid}`)
    if (!el) {
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    if (!el.enabled_tshirts)
      el.enabled_tshirts = []
    this.formValue = el
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'tokens',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Token saved'})
        this.$router.push({name: 'tokens'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.error}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'tokens'})
    },
    rateToNative(val) {
      const d = new Decimal(val)
      const one = new Decimal(1)
      const op = one.dividedBy(d)
      return `1 : ${op.toString()}`
    },
    toggleTShirt(ttype) {
      if (!Array.isArray(this.formValue.enabled_tshirts)) {
        this.formValue.enabled_tshirts = []
      }
      if (this.formValue.enabled_tshirts.includes(ttype)) {
        this.formValue.enabled_tshirts = this.formValue.enabled_tshirts.filter(t => t !== ttype)
      } else {
        this.formValue.enabled_tshirts.push(ttype)
      }
    },
    isTShirt(ttype) {
      if (!Array.isArray(this.formValue.enabled_tshirts)) {
        this.formValue.enabled_tshirts = []
      }
      return this.formValue.enabled_tshirts.includes(ttype)
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete Token?',
        message: 'Token information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'tokens',
        id: this.formValue.token_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'tokens'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew && !!formValue.is_virtual"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row"><div class="col"><h3>Token Settings</h3></div></div>
    <div class="row">
      <TextField
        v-model="formValue.meta_name"
        :metadata="formFields.meta_name"
      />
      <TextField
        v-model="formValue.meta_ticker"
        :metadata="formFields.meta_ticker"
        :disabled="!isVirtual"
      />
    </div>
    <div v-if="!isVirtual" class="row">
      <TextField
        v-model="formValue.native_name"
        :metadata="formFields.native_name"
      />
      <TextField
        v-model="formValue.native_ticker"
        :metadata="formFields.native_ticker"
        :disabled="!isNew || !formValue.is_virtual"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Token Exchange</h3></div></div>
    <div v-if="!isVirtual" class="row">
      <TextField
        :modelValue="rateToNative(formValue.native_rate)"
        :metadata="formFields.native_rate"
        :disabled="true"
      />
    </div>
    <div v-else class="row">
      <TextField
        v-model="formValue.virtual_rate"
        :metadata="formFields.virtual_rate"
      />
      <DropdownField
        v-model="formValue.virtual_ticker"
        :metadata="formFields.virtual_ticker"
      />
      <TextField
        v-model="formValue.virtual_ticker_rate"
        :metadata="formFields.virtual_ticker_rate"
      />
    </div>
    <hr/>
    <div class="row"><div class="col"><h3>Global limits/renew</h3></div></div>
    <div class="row mb-3">
      <TextField
        v-model="formValue.total_limit"
        :metadata="formFields.total_limit"
      />
      <TextField
        v-model="formValue.renew_hours_total"
        :metadata="formFields.renew_hours_total"
      />
    </div>
    <div class="row"><div class="col"><h5>Private Area</h5></div></div>
    <div class="row">
      <TextField
        v-model="formValue.private_expand"
        :metadata="formFields.private_expand"
      />
    </div>
    <div class="row"><div class="col"><h3>T-Shirt Modifiable</h3></div></div>
    <div class="row">
      <TextField
        v-model="formValue.user_limit"
        :metadata="formFields.user_limit"
      />
      <TextField
        v-model="formValue.renew_hours"
        :metadata="formFields.renew_hours"
      />
    </div>
    <div class="row">
      <TextField
        v-model="formValue.spawn_range"
        :metadata="formFields.spawn_range"
      />
      <TextField
        v-model="formValue.spawn_range_max"
        :metadata="formFields.spawn_range_max"
      />
    </div>
    <div class="row"><div class="col"><h5>Private Area</h5></div></div>
    <div class="row">
      <TextField
        v-model="formValue.private_r"
        :metadata="formFields.private_r"
      />
      <TextField
        v-model="formValue.private_v"
        :metadata="formFields.private_v"
      />
    </div>
    <div class="row"><div class="col">
      <h3>T-Shirts</h3>
      <div class="text-muted">
        <small>Token will be availabel to collect only if player has t-shirt of type</small>
      </div>
    </div></div>
    <div class="row mb-3">
      <div v-for="tsh, tshi of tshirts" :key="tshi" class="col-12" :class="`col-sm-${tshirtColSize}`">
        <input :disabled="$uiBusy" type="checkbox" class="form-check-input" :id="`tshirt-${tsh.tshirt_type}`" :checked="isTShirt(tsh.tshirt_type)" @change="toggleTShirt(tsh.tshirt_type)"/>
        <label :for="`tshirt-${tsh.tshirt_type}`" class="ms-2">{{tsh.tshirt_type}}</label>
      </div>
    </div>
    <div class="row"><div class="col"><h3>Icon</h3></div></div>
    <div class="row">
      <UploadImageField
        v-model="formValue.with_icon"
        :metadata="formFields.with_icon"
        :owner-id="formValue.token_id || null"
        data-key="tokens"
      />
    </div>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="tshirt-active" v-model="formValue.active"/>
        <label for="tshirt-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
