<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'UsersView',
  components: {
    TablePage
  }
}
</script>

<template>
<TablePage
  title="Users"
  data-key="users"
  :canRead="true"
  :canAdd="$isRoot"
  add-button="New User"
  search-field="email"
/>
</template>
