<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'LanguagesView',
  components: {
    TablePage
  }
}
</script>

<template>
<TablePage
  title="Languages and Translations"
  data-key="languages"
  :canAdd="true"
  add-button="Add Localization"
/>
</template>
