<script>
export default {
  name: 'ConfirmDialog',
  methods: {
    complete(res) {
      this.$store.commit('ui/completeConfirm', res)
    }
  }
}
</script>

<template>
<div class="modal fade show" :class="{'d-block': !!$store.state.ui.dialogConfirm.resolve }" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{$store.state.ui.dialogConfirm.title}}</h5>
        <button type="button" @click="complete(false)" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{$store.state.ui.dialogConfirm.message}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" @click="complete(false)" class="btn btn-secondary">Cancel</button>
        <button type="button" @click="complete(true)" class="btn btn-primary">Ok</button>
      </div>
    </div>
  </div>
</div>
</template>

<style>
.modal:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}
</style>
