<script>
import StatBlock from '@/components/StatBlock'
import TokenStatBlock from '@/components/TokenStatBlock'
import Decimal from 'decimal.js'

export default {
  name: 'HomeView',
  components: {
    StatBlock,
    TokenStatBlock
  },
  data() {
    return {
      selectedToken: 'metaTDX',
      statPeriod: 24
    }
  },
  async created() {
    const ok = await this.loadUsersStats()
    if (ok)
      await this.loadTStats()
  },
  computed: {
    selectedName() {
      if (!this.$store.state.tstats[this.selectedToken]) return '...'
      return this.$store.state.tstats[this.selectedToken].meta_name || this.selectedToken
    },
    selectedNative() {
      if (!this.$store.state.tstats[this.selectedToken]) return '...'
      return this.$store.state.tstats[this.selectedToken].native_name || this.$store.state.tstats[this.selectedToken].native_ticker
    }
  },
  methods: {
    async loadUsersStats() {
      this.$store.commit('ui/busy', true)
      const ok = await this.$store.dispatch('loadUStats', { period: this.statPeriod })
      if (!ok) {
        this.$store.dispatch('auth/logout')
        this.$router.push({ name: 'login' })
        this.$store.commit('ui/busy', false)
        return false
      }
      this.$store.commit('ui/busy', false)
      return true
    },
    async loadTStats() {
      this.$store.commit('ui/busy', true)
      await this.$store.dispatch('loadTStats')
      this.$store.commit('ui/busy', false)
    },
    tokens() {
      return Object.keys(this.$store.state.tstats || {}).filter(x => x !== 'success')
    },
    percentNum(total, num) {
      const n = parseInt(num) || 0
      const t = parseInt(total) || 0
      const ret = (n/(t/100)).toFixed(2)
      if (isNaN(ret)) return 0
      if (!isFinite(ret)) return 0
      return ret
    },
    async selectStatPeriod() {
      await this.loadUsersStats()
    },
    convertMetaToNative(meta = 0, rate = 0) {
      if (!meta || !rate) return 0
      const metaD = new Decimal(meta)
      const rateD = new Decimal(rate)
      const ret = metaD.mul(rateD)
      return ret
    },
    avg(total = 0, users = 0) {
      if (!total || !users) return 0
      const totalD = new Decimal(total)
      const usersD = new Decimal(users)
      const ret = totalD.div(usersD)
      return ret
    }
  }
}
</script>

<template>
<div class="table-form bg-white m-3">
  <div class="table-page-header d-flex p-3 justify-content-between border-bottom border-gray">
    <h2 class="flex-fill mb-0">Statistic for all users</h2>
    <span>
      <select :disabled="$uiBusy" class="form-select flex-shrink-1" @change="selectStatPeriod()" v-model="statPeriod">
        <option value="24">1 day</option>
        <option value="168">1 week</option>
        <option value="672">1 month</option>
        <option value="-1">All</option>
      </select>
    </span>
  </div>
  <div class="py-3 px-4 container-fluid">
    <div class="row">
      <StatBlock
        :stat-number="$store.state.stats?.registered"
        label="Total users"
      />
      <StatBlock
        :stat-number="$store.state.stats?.tidex"
        label="Registration with Exchange"
        :stat-percent="percentNum($store.state.stats?.registered, $store.state.stats?.tidex)"
      />
      <StatBlock
        :stat-number="$store.state.stats?.ntidex"
        label="Registration without Exchange"
        :stat-percent="percentNum($store.state.stats?.registered, $store.state.stats?.ntidex)"
      />
      <StatBlock
        :stat-number="$store.state.stats?.tshirt"
        label="Users with t-shirts"
        :stat-percent="percentNum($store.state.stats?.registered, $store.state.stats?.tshirt)"
      />
      <StatBlock
        :stat-number="$store.state.stats?.ntshirt"
        label="Users without t-shirts"
        :stat-percent="percentNum($store.state.stats?.registered, $store.state.stats?.ntshirt)"
      />
    </div>
  </div>
</div>
<div class="table-form bg-white m-3">
  <div class="table-page-header d-flex p-3 border-bottom border-gray">
    <h2 class="flex-fill mb-0">Statistic for {{selectedName}}</h2>
    <span>
      <select class="form-select flex-shrink-1" v-model="selectedToken">
        <option v-for="token, ti of tokens()" :key="ti" :value="token">{{token}}</option>
      </select>
    </span>
  </div>
  <div class="py-3 px-4 container-fluid">
    <h4 class="flex-fill mb-0">All users</h4>
  </div>
  <div class="pya-3 px-4 container-fluid">
    <div class="row">
      <TokenStatBlock
        :label="`Mined <b>${selectedToken}</b> Tokens`"
        :stat-number="$store.state.tstats[selectedToken]?.mined"
      />
      <TokenStatBlock
        :label="`Withdraw <b>${selectedToken}</b>`"
        :stat-number="$store.state.tstats[selectedToken]?.withdraw"
      />
      <TokenStatBlock
        :label="`Approximately <b>${selectedNative}</b> amount`"
        :stat-number="convertMetaToNative($store.state.tstats[selectedToken]?.mined, $store.state.tstats[selectedToken]?.rate)"
      />
      <TokenStatBlock
        :label="`Converted <b>${selectedNative}</b>`"
        :stat-number="convertMetaToNative($store.state.tstats[selectedToken]?.withdraw, $store.state.tstats[selectedToken]?.rate)"
      />
    </div>
  </div>

  <div class="py-3 px-4 container-fluid">
    <h4 class="flex-fill mb-0">Avarrage for 1 user</h4>
  </div>
  <div class="pb-3 px-4 container-fluid">
    <div class="row">
      <TokenStatBlock
        :label="`Mined <b>${selectedToken}</b> Tokens`"
        :stat-number="avg($store.state.tstats[selectedToken]?.mined, $store.state.tstats[selectedToken]?.avg)"
      />
      <TokenStatBlock
        :label="`Withdraw <b>${selectedToken}</b>`"
        :stat-number="avg($store.state.tstats[selectedToken]?.withdraw, $store.state.tstats[selectedToken]?.avg)"
      />
      <TokenStatBlock
        :label="`Approximately <b>${selectedNative}</b> amount`"
        :stat-number="avg(convertMetaToNative($store.state.tstats[selectedToken]?.mined, $store.state.tstats[selectedToken]?.rate), $store.state.tstats[selectedToken]?.avg)"
      />
      <TokenStatBlock
        :label="`Converted <b>${selectedNative}</b>`"
        :stat-number="avg(convertMetaToNative($store.state.tstats[selectedToken]?.withdraw, $store.state.tstats[selectedToken]?.rate), $store.state.tstats[selectedToken]?.avg)"
      />
    </div>
  </div>
</div>
</template>
