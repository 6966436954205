<script>
import TextField from '@/components/fields/TextField'
export default {
  name: 'GameSettings',
  components: {
    TextField
  },
  props: ['tokens', 'modelValue'],
  emits: ['update:modelValue'],
  data() {
    const ret = {
      metadata: this.tokens.reduce((acc, t) => {
        acc[t.meta_ticker] = {
          label: `${t.meta_name || t.meta_ticker} required`,
          type: 'number'
        }
        return acc
      }, {}),
      value: {
        limits: this.tokens.reduce((acc, t) => {
          acc[t.meta_ticker] = 0
          return acc
        }, {})
      }
    }
    ret.value.interval = 24
    ret.metadata.interval = {
      type: 'number',
      label: 'Interval in hours to play game'
    }
    ret.value.gameDuration = 0
    ret.metadata.gameDuration = {
      type: 'number',
      label: 'Game Duration (sec, 0 for unlimited)'
    }
    return ret
  },
  async created() {
    if (!this.modelValue || !Object.keys(this.modelValue || {}).length) {
      setTimeout(() => {
        this.$emit('update:modelValue', this.value)
      }, 0)
    } else {
      this.value = { ...this.value, ...this.modelValue }
    }
  },
  watch: {
    modelValue(next) {
      if (next && next.limits) {
        this.value = next
      }
    }
  },
  methods: {
    updValue() {
      this.$emit('update:modelValue', this.value)
      console.log(this.value)
    }
  }
}
</script>

<template>
<div class="card mb-3">
  <div class="card-body">
    <div class="row">
      <TextField
        v-model="value.interval"
        :metadata="metadata.interval"
        :disabled="$uiBusy"
        @update:modelValue="updValue()"
      />
      <TextField
        v-model="value.gameDuration"
        :metadata="metadata.gameDuration"
        :disabled="$uiBusy"
        @update:modelValue="updValue()"
      />
    </div>
    <div class="row">
      <TextField
        v-for="t, ti of tokens"
        :key="ti"
        v-model="value.limits[t.meta_ticker]"
        :metadata="metadata[t.meta_ticker]"
        :disabled="$uiBusy"
        @update:modelValue="updValue()"
      />
    </div>
  </div>
</div>
</template>
