<script>
export default {
  name: 'TokenStatsBlock',
  components: {
  },
  props: ['statNumber', 'label']
}
</script>

<template>
<div class="p-1 col-12 col-sm-4 col-md-3">
  <div class="py-3 px-4 border border-grey">
    <div class="labekl" :innerHTML="label"/>
    <div class="numbers d-flex">
      <div class="main me-3"><b>{{statNumber}}</b></div>
    </div>
  </div>
</div>
</template>
