<script>
export default {
  name: 'TextField',
  props: ['metadata','modelValue', 'disabled'],
  emits: ['update:modelValue'],
  computed: {
    dataType() {
      if (this.metadata.type === 'integer') return 'number'
      if (this.metadata.type === 'number') return 'number'
      if (this.metadata.type === 'date') return 'date'
      if (this.metadata.type === 'password') return 'password'
      if (this.metadata.type === 'boolean') return 'checkbox'
      return 'text'
    }
  },
  data() {
    return {
      value: this.modelValue
    }
  },
  watch: {
    modelValue(next) {
      if (this.value !== next)
        this.value = next
    }
  },
  methods: {
    onChanged() {
      if (this.dataType === 'checkbox') this.value = !this.value
      this.$emit('update:modelValue', this.value)
    }
  }
}
</script>

<template>
<div class="col-12 col-sm-6 p-2">
  <div class="label">
    <label class="text-muted">{{metadata?.label}}</label>
  </div>
  <div class="field">
    <input
      :type="dataType"
      :disabled="$uiBusy || disabled"
      :class="{
        'form-control': dataType !== 'checkbox',
        'form-check-input': dataType === 'checkbox',
      }"
      @input="onChanged()"
      v-model="value"
    />
  </div>
  <div class="mb-2" v-if="metadata?.help">
    <small class="text-muted">{{metadata.help}}</small>
  </div>
</div>
</template>
