<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import UploadImageField from '@/components/fields/UploadImageField'
import TextareaField from '@/components/fields/TextareaField'
// import Decimal from 'decimal.js'

export default {
  name: 'TokenForm',
  components: {
    FormPage,
    TextareaField,
    UploadImageField,
    TextField
  },
  data() {
    return {
      isNew: true,
      tshirts: [],
      formFields: {
        name: {
          type: 'text',
          label: 'Name',
        },
        text: {
          type: 'text',
          label: 'Text'
        },
        url: {
          type: 'text',
          label: 'Link Url'
        },
        has_banner: {
          type: 'image',
          label: 'Image',
          table: 'news',
          field: 'has_banner',
          path: 'news'
        },
      },
      formValue: {
        name: '',
        text: '',
        url: '',
        active: false
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'Add News' : 'Edit News'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    if (this.$route.name === 'newsAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const nid = this.$route.params.nid
    const el = await this.$store.dispatch('tables/loadOne', {table: 'news', id: nid})
    if (!el) {
      this.$store.dispatch('ui/popup', {type: 'warn', message: 'Not Found!'})
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el.data
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'news',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Saved!'})
        this.$router.push({name: 'news'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.error}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'news'})
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete News?',
        message: 'Information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'news',
        id: this.formValue.news_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'news'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row">
      <TextField
        v-model="formValue.name"
        :metadata="formFields.name"
      />
      <TextField
        v-model="formValue.url"
        :metadata="formFields.url"
      />
    </div>
    <div class="row">
      <TextareaField
        v-model="formValue.text"
        :metadata="formFields.text"
      />
    </div>
    <div class="row"><div class="col"><h3>Banner</h3></div></div>
    <div class="row">
      <UploadImageField
        v-model="formValue.has_banner"
        :metadata="formFields.has_banner"
        :owner-id="formValue.news_id || null"
        data-key="news"
      />
    </div>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="news-active" v-model="formValue.active"/>
        <label for="news-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
