<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import TokenModifiers from '@/components/tshirts/TokenModifiers'
import GameSettings from '@/components/tshirts/GameSettings'

export default {
  name: 'TShirtsForm',
  components: {
    FormPage,
    TokenModifiers,
    GameSettings,
    TextField
  },
  data() {
    return {
      isNew: true,
      tokens: [],
      formFields: {
        tshirt_type: {
          type: 'text',
          label: 'Type',
        },
      },
      formValue: {
        tshirt_type: '',
        modifiers: {},
        game_settings: {},
        active: false
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'Add T-Shirt' : 'Edit T-Shirt'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    if (this.$route.name === 'newsAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const tokensReq = await this.$store.dispatch('tables/loadTable', {key: 'tokens'})
    if (!tokensReq.success) {
      this.$store.dispatch('ui/popup', {type: 'warn', message: 'Loading tokens failed!'})
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.tokens = tokensReq.data
    const tid = this.$route.params.tid
    const el = await this.$store.dispatch('tables/loadOne', {table: 'tshirts', id: tid})
    if (!el) {
      this.$store.dispatch('ui/popup', {type: 'warn', message: 'Not Found!'})
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    const eld = el.data
    if (!eld.modifiers) eld.modifiers = {}
    if (Object.keys(eld.modifiers) < this.tokens.length)
      this.tokens.forEach(t => {
        eld.modifiers[t.meta_ticker] = {
          active: false,
          user_limit: { operation: '+', dimension: '%', modifier: 1 },
          renew_hours: { operation: '+', dimension: '%', modifier: 1 },
          spawn_range: { operation: '+', dimension: '%', modifier: 1 },
          spawn_range_max: { operation: '+', dimension: '%', modifier: 1 },
          private_r: { operation: '+', dimension: '%', modifier: 1 },
        }
      })
    this.formValue = eld
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'tshirts',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Saved!'})
        this.$router.push({name: 'tshirts'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.error}`})
      }
    },
    formCancel() {
      this.$router.push({name: 'tshirts'})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="false"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
>
  <div class="container">
    <div class="row">
      <TextField
        v-model="formValue.tshirt_type"
        :metadata="formFields.tshirt_type"
        :disabled="true"
      />
    </div>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="tshirt-active" v-model="formValue.active"/>
        <label for="tshirt-active" class="ms-2">Active</label>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <h3>Mini Game Tokens Limits</h3>
        <small class="text-muted">This number will be reduced from total player limits so player can't go over limit because of game. Player never see it.</small>
      </div>
    </div>
    <GameSettings
      v-if="tokens?.length"
      :tokens="tokens"
      v-model="formValue.game_settings"
    />
    <div class="row mt-3">
      <div class="col">
        <h3>Map Game Tokens Modifiers</h3>
        <small class="text-muted">Modifiers for map game. For multiple t-shirts of same type - modifiers multiple on tshirt amount.</small>
      </div>
    </div>
    <div v-if="tokens?.length">
      <TokenModifiers
        v-for="token, ti of tokens"
        :key="ti"
        :token="token"
        v-model="formValue.modifiers[token.meta_ticker]"
      />
    </div>
  </div>
</FormPage>
</template>
