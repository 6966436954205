<script>
export default {
  name: 'FormPage',
  props: ['isNew', 'canDelete', 'title'],
  emits: ['clickSave', 'clickCancel', 'clickDelete']
}
</script>

<template>
<div class="table-form bg-white m-3">
  <div class="table-page-header d-flex p-3 border-bottom border-gray">
    <h2 class="flex-fill mb-0">{{title}}</h2>
  </div>
  <div class="form-body py-3 px-4">
    <slot/>
  </div>
  <div class="form-footer d-flex border-top border-gray">
    <div class="p-3 ps-5">
      <button :disabled="$uiBusy" @click="$emit('clickSave')" class="btn btn-success">
        Save
      </button>
    </div>
    <div class="p-3">
      <button :disabled="$uiBusy" @click="$emit('clickCancel')" class="btn btn-outline-secondary">
        Cancel
      </button>
    </div>
    <div class="flex-fill text-end p-3 pe-5">
      <button :disabled="$uiBusy" v-if="canDelete" @click="$emit('clickDelete')" class="btn btn-danger">
        DELETE
      </button>
    </div>
  </div>
</div>
</template>
