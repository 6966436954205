<script>
import ReadOnlyField from '@/components/fields/ReadOnlyField'
import ViewPage from '@/components/ViewPage'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'

export default {
  name: 'UserForm',
  components: {
    ReadOnlyField,
    ViewPage,
  },
  data() {
    return {
      isNew: true,
      user: {
        email: '',
        full_name: '',
        gender: '',
        birth_date: null,
        country: '',
        city: '',
        avatar: false,
        tshirts: [],
        is_tidex: false,
        is_verified: false,
        is_admin: false,
        is_root: false,
      },
      stats: {}
    }
  },
  computed: {
    formTitle() {
      return this.user.email
    },
    classicTshirts() {
      let ret = (this.user.tshirts || []).reduce((acc, t) => t.type === 'classic' ? acc + t.amount : acc, 0)
      return ret
    },
    premiumTshirts() {
      let ret = (this.user.tshirts || []).reduce((acc, t) => t.type === 'premium' ? acc + t.amount : acc, 0)
      return ret
    },
    tokens() {
      return Object.keys(this.stats)
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    const uid = this.$route.params.uid
    const data = await this.$store.dispatch('loadUserStats', {uid})
    if (!data) {
      this.$store.dispatch('ui/popup', {type: 'warn', message: 'Not Found!'})
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.$router.push({ name: 'users' })
      }, 0)
      return
    }
    this.user = data.user
    this.stats = data.stats
    this.$store.commit('ui/busy', false)
  },
  methods: {
    fBoolean(v) {
      return v === 'true' ? 'Yes' : 'No'
    },
    fDate(v, f) {
      const d = dayjs(v)
      if (!d.isValid()) return ''
      return f ? d.format(f) : d.format()
    },
    fGender(v) {
      if (v === 'm') return 'male'
      if (v === 'f') return 'female'
      return 'unknown'
    },
    convertMetaToNative(meta = 0, rate = 0) {
      if (!meta || !rate) return 0
      const metaD = new Decimal(meta)
      const rateD = new Decimal(rate)
      const ret = metaD.mul(rateD)
      return ret
    }
  }
}
</script>

<template>
<ViewPage
  :title="formTitle"
>
  <div class="container">
    <div class="row">
      <ReadOnlyField
        :value="user.email"
        label="E-mail"
      />
      <ReadOnlyField
        :value="fBoolean(user.is_verified)"
        label="Verified"
        cls="col-6 col-sm-3"
      />
      <ReadOnlyField
        :value="fBoolean(user.is_tidex)"
        label="Reg on Tidex"
        cls="col-6 col-sm-3"
      />
    </div>
    <div class="row my-3"><div class="col"><h3>Profile</h3></div></div>
    <div class="row">
      <ReadOnlyField
        :value="user.full_name"
        label="Full Name"
      />
      <ReadOnlyField
        :value="fDate(user.birth_date, 'DD.MM.YYYY')"
        label="Birth Date"
        cls="col-6 col-sm-3"
      />
      <ReadOnlyField
        :value="fGender(user.gender)"
        label="Gender"
        cls="col-6 col-sm-3"
      />
    </div>
    <div class="row">
      <ReadOnlyField
        :value="user.country"
        label="Country"
      />
      <ReadOnlyField
        :value="user.city"
        label="City"
      />
    </div>
    <div class="row my-3"><div class="col"><h3>TShirts</h3></div></div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <span class="text-muted me-2">Classic:</span>
        <span class="text-bold">{{classicTshirts}}</span>
      </div>
      <div class="col-12 col-sm-6">
        <span class="text-muted me-2">Premium:</span>
        <span class="text-bold">{{premiumTshirts}}</span>
      </div>
    </div>
    <div class="row my-3"><div class="col"><h3>Token statistics</h3></div></div>
    <div class="row d-none d-md-block">
      <div class="col-12">
        <table class="table w-100">
          <thead>
            <tr>
              <th>Token</th>
              <th>Mined</th>
              <th>Withdraw</th>
              <th>Approximately amount</th>
              <th>Converted</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="token, ti of tokens" :key="ti">
              <td>{{stats[token].name || token}}</td>
              <td>{{stats[token].mined}} {{token}}</td>
              <td>{{stats[token].withdraw}} {{token}}</td>
              <td>{{convertMetaToNative(stats[token].mined, stats[token].rate)}} {{stats[token].native_ticker}}</td>
              <td>{{convertMetaToNative(stats[token].mined, stats[token].rate)}} {{stats[token].native_ticker}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-for="token, ti of tokens" :key="ti" class="d-block d-md-none">
      <div class="row mt-3">
        <div class="col-6 text-start text-muted">Token</div>
        <div class="col-6 text-end">{{token.name || token}}</div>
      </div>
      <div class="row">
        <div class="col-6 text-start text-muted">Mined</div>
        <div class="col-6 text-end">{{stats[token].mined}} {{token}}</div>
      </div>
      <div class="row">
        <div class="col-6 text-start text-muted">Withdraw</div>
        <div class="col-6 text-end">{{stats[token].withdraw}} {{token}}</div>
      </div>
      <div class="row">
        <div class="col-6 text-start text-muted">Approximately amount</div>
        <div class="col-6 text-end">{{convertMetaToNative(stats[token].mined, stats[token].rate)}} {{stats[token].native_ticker}}</div>
      </div>
      <div class="row">
        <div class="col-6 text-start text-muted">Converted</div>
        <div class="col-6 text-end">{{convertMetaToNative(stats[token].mined, stats[token].rate)}} {{stats[token].native_ticker}}</div>
      </div>
    </div>
    <div class="row my-3"><div class="col"><h3>Roles</h3></div></div>
    <div class="row">
      <ReadOnlyField
        :value="fBoolean(user.is_admin)"
        label="Is Admin"
      />
      <ReadOnlyField
        :value="fBoolean(user.is_root)"
        label="Is Super Admin"
      />
    </div>
  </div>
</ViewPage>
</template>
