<script>
export default {
  name: 'UploadImageField',
  props: ['metadata','modelValue', 'disabled', 'ownerId', 'dataKey','isFile'],
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue,
      loaded: null
    }
  },
  computed: {
    hasUpload() {
      return !!this.value
    },
    currentSrc() {
      if (this.loaded) return this.value
      return `${this.imgBase}/${this.ownerId}${this.acceptStr}`
    },
    valuePath() {
      if (!this.hasUpload) return this.isFile ? 'No file' : 'No image'
      if (this.loaded) return this.loaded
      return `/${this.metadata.path}/${this.ownerId}${this.acceptStr}`
    },
    supported() {
      return typeof window.FileReader === 'function'
    },
    imgBase() {
      return process.env.VUE_APP_IMG_BASE + '/' + this.dataKey
    },
    acceptStr() {
      return this.isFile ? '.json' : '.png'
    }
  },
  watch: {
    modelValue(next) {
      if (this.value !== next)
        this.value = next
    }
  },
  methods: {
    selectFile() {
      this.$refs.file.click()
    },
    fileChanged() {
      this.$store.commit('ui/busy', true)
      if (!this.$refs.file.files[0]) {
        this.value = false
        this.loaded = null
        this.$emit('update:modelValue', this.value)
        this.$store.commit('ui/busy', false)
        return
      }
      this.loaded = this.$refs.file.files[0].name
      if (!this.isFile) {
        const reader = new FileReader()
        reader.onload = () => {
          this.value = reader.result
          this.$emit('update:modelValue', this.value)
          this.$store.commit('ui/busy', false)
        }
        reader.readAsDataURL(this.$refs.file.files[0])
      } else {
        this.value = this.$refs.file.files[0]
        this.$emit('update:modelValue', this.value)
        this.$store.commit('ui/busy', false)
      }
    },
    clearFile() {
      this.loaded = null
      this.value = false
      this.$emit('update:modelValue', this.value)
    }
  }
}
</script>

<template>
<div class="col-12">
  <div class="label">
    <label class="text-muted">{{metadata.label}}</label>
  </div>
  <div class="field">
    <input
      type="text"
      readonly
      class="form-control"
      :value="valuePath"
    />
  </div>
  <div class="controls mt-2 d-flex justify-content-start">
    <input type="file" :accept="acceptStr" @change="fileChanged()" class="d-none" ref="file"/>
    <button @click="selectFile()" :disabled="$uiBusy || disabled || !supported" class="btn btn-link me-2">Upload</button>
    <button @click="clearFile()" v-if="hasUpload" :disabled="$uiBusy || disabled" class="btn btn-link">Clear</button>
  </div>
  <div v-if="!isFile && (hasUpload || loaded)">
    <img class="img-fluid" :src="currentSrc"/>
  </div>
  <div v-if="!supported" class="mb-2 text-danger">
    It looks like your browser doesn't supports <a href="https://www.w3.org/TR/file-upload/" target="_blnak">File API</a>. Uploading is not possible.
  </div>
  <div class="mb-2" v-if="metadata?.help">
    <small class="text-muted">{{metadata.help}}</small>
  </div>
</div>
</template>
