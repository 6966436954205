<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'TokensView',
  components: {
    TablePage
  },
  data() {
    return {
      
    }
  },
  async created() {

  },
  methods: {
    addToken() {
      console.log('add')
    }
  }
}
</script>

<template>
<TablePage
  title="Tokens"
  data-key="tokens"
  :canAdd="true"
  add-button="Add Virtual"
  @add="addToken()"
/>
</template>
