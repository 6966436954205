<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import DropdownField from '@/components/fields/DropdownField'

export default {
  name: 'UserForm',
  components: {
    FormPage,
    DropdownField,
    TextField
  },
  data() {
    return {
      isNew: true,
      tshirts: [],
      formFields: {
        email: {
          type: 'text',
          label: 'E-mail',
        },
        full_name: {
          type: 'text',
          label: 'Name',
        },
        gender: {
          type: 'text',
          label: 'Gender',
          list: [{
            key: 'm',
            label: 'Male'
          }, {
            key: 'f',
            label: 'Female'
          }]
        },
        birth_date: {
          type: 'date',
          label: 'Birth Date'
        },
        city: {
          type: 'text',
          label: 'City'
        },
        country: {
          type: 'text',
          label: 'Country',
        },
        password: {
          type: 'password',
          label: 'Password',
        },
        password2: {
          type: 'password',
          label: 'Confirm Password',
        },
      },
      formValue: {
        email: '',
        password: '',
        password2: '',
        full_name: '',
        gender: '',
        birth_date: null,
        country: '',
        city: '',
        avatar: false,
        tshirts: [],
        is_tidex: false,
        is_verified: false,
        is_admin: false,
        is_root: false,
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'New User' : 'Edit User'
    },
    classicTshirts() {
      let ret = (this.formValue.tshirts || []).reduce((acc, t) => t.type === 'classic' ? acc + t.amount : acc, 0)
      return ret
    },
    premiumTshirts() {
      let ret = (this.formValue.tshirts || []).reduce((acc, t) => t.type === 'premium' ? acc + t.amount : acc, 0)
      return ret
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    if (this.$route.name === 'userAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const uid = this.$route.params.uid
    const el = await this.$store.dispatch('tables/loadOne', {table: 'users', id: uid})
    if (!el) {
      this.$store.dispatch('ui/popup', {type: 'warn', message: 'Not Found!'})
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el.data
    this.formValue.password = ''
    this.formValue.password2 = ''
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'users',
        data: this.formValue,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Saved!'})
        this.$router.push({name: 'users'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.error}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'users'})
    },
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="false"
  :can-add="$isRoot"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
>
  <div class="container">
    <div class="row">
      <TextField
        v-model="formValue.email"
        :metadata="formFields.email"
      />
    </div>
    <div class="row my-3">
      <div class="col">
        <h3>Password</h3>
        <div><small class="text-muted">Don't set if you don't want change password</small></div>
      </div>
    </div>
    <div class="row">
      <TextField
        v-model="formValue.password"
        :metadata="formFields.password"
      />
      <TextField
        v-model="formValue.password2"
        :metadata="formFields.password2"
      />
    </div>
    <div class="row my-3"><div class="col"><h3>Profile</h3></div></div>
    <div class="row">
      <TextField
        v-model="formValue.full_name"
        :metadata="formFields.full_name"
      />
      <TextField
        v-model="formValue.birth_date"
        :metadata="formFields.birth_date"
      />
      <DropdownField
        v-model="formValue.gender"
        :metadata="formFields.gender"
      />
    </div>
    <div class="row">
      <TextField
        v-model="formValue.country"
        :metadata="formFields.country"
      />
      <TextField
        v-model="formValue.city"
        :metadata="formFields.city"
      />
    </div>
    <div class="row my-3"><div class="col"><h3>TShirts</h3></div></div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <span class="text-muted me-2">Classic:</span>
        <span class="text-bold">{{classicTshirts}}</span>
      </div>
      <div class="col-12 col-sm-6">
        <span class="text-muted me-2">Premium:</span>
        <span class="text-bold">{{premiumTshirts}}</span>
      </div>
    </div>
    <div class="row my-3"><div class="col"><h3>Roles</h3></div></div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="text-muted d-block">Is Admin</label>
        <input :disabled="!$isRoot" type="checkbox" class="form-check-input" v-model="formValue.is_admin"/>
      </div>
      <div class="col-12 col-sm-6">
        <label class="text-muted d-block">Is Super Admin</label>
        <input :disabled="!$isRoot" type="checkbox" class="form-check-input" v-model="formValue.is_root"/>
      </div>
    </div>
    <div class="row my-3"><div class="col"><h3>Verification</h3></div></div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label class="text-muted d-block">Registered at TIDEX</label>
        <input type="checkbox" class="form-check-input" disabled v-model="formValue.is_tidex"/>
      </div>
      <div class="col-12 col-sm-6">
        <label class="text-muted d-block">Email Verified</label>
        <input type="checkbox" class="form-check-input" v-model="formValue.is_verified"/>
      </div>
    </div>
  </div>
</FormPage>
</template>
