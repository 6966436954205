<script>
import ModifierField from '@/components/fields/ModifierField'
export default {
  name: 'TokenModifiers',
  components: {
    ModifierField
  },
  props: ['token', 'modelValue', 'disabled'],
  emits: ['update:modelValue'],
  data() {
    return {
      metadata: {
        user_limit: {
          type: 'number',
          label: 'Player Limit',
          help: 'Modifier of max possible tokens player can collect.'
        },
        renew_hours: {
          type: 'number',
          label: 'Player Renew Hours',
          help: 'Modify how many hours token need to wait before restored for player if token is limited'
        },
        spawn_range: {
          type: 'number',
          label: 'Minimal spawn R',
          help: 'Modify minimal ditance in meters to spawn token.'
        },
        spawn_range_max: {
          type: 'number',
          label: 'Maximum spawn R',
          help: 'Modify maximum ditance in meters to spawn token.'
        },
        private_r: {
          type: 'number',
          label: 'Private R',
          help: 'Modify Private R to spawn token.'
        },
        private_v: {
          type: 'number',
          label: 'Private Volume',
          help: 'Modify Private Volume to spawn token.'
        },
        use_multiplier: {
          type: 'boolean',
          label: 'Use multiplier',
          help: 'Mutiply modifier on amount of player\'s tshirts'
        },
      },
      value: {
        active: false,
        user_limit: {
          modifier: 1,
          operation: '+',
          dimension: '%',
          use_multiplier: false
        },
        renew_hours: {
          modifier: 1,
          operation: '+',
          dimension: '%',
          use_multiplier: false
        },
        spawn_range: {
          modifier: 1,
          operation: '+',
          dimension: '%',
          use_multiplier: false
        },
        spawn_range_max: {
          modifier: 1,
          operation: '+',
          dimension: '%',
          use_multiplier: false
        },
        private_r: {
          modifier: 1,
          operation: '+',
          dimension: '%',
          use_multiplier: false
        },
        private_v: {
          modifier: 1,
          operation: '+',
          dimension: '%',
          use_multiplier: false
        }
      }
    }
  },
  async created() {
    if (!this.modelValue) {
      setTimeout(() => {
        this.$emit('update:modelValue', this.value)
      }, 0)
    } else {
      this.value = { ...this.value, ...this.modelValue }
    }
  },
  watch: {
    modelValue(next) {
      if (this.value !== next)
        this.value = next
    }
  },
}
</script>

<template>
<div class="card mb-3">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <h5 class="card-title">{{token.meta_ticker}}</h5>
      <div class="form-check form-switch">
        <label class="form-check-label" :for="`modifiers-${token.meta_ticker}`">Active</label>
        <input v-model="value.active" class="form-check-input" type="checkbox" role="switch" :id="`modifiers-${token.meta_ticker}`">
      </div>
    </div>
    <div class="row">
      <ModifierField
        v-model="value.user_limit"
        :metadata="metadata.user_limit"
        :disabled="!value.active"
      />
      <ModifierField
        v-model="value.renew_hours"
        :metadata="metadata.renew_hours"
        :disabled="!value.active"
      />
    </div>
    <div class="row">
      <ModifierField
        v-model="value.spawn_range"
        :metadata="metadata.spawn_range"
        :disabled="!value.active"
      />
      <ModifierField
        v-model="value.spawn_range_max"
        :metadata="metadata.spawn_range_max"
        :disabled="!value.active"
      />
    </div>
    <div class="row">
      <ModifierField
        v-model="value.private_r"
        :metadata="metadata.private_r"
        :disabled="!value.active"
      />
      <ModifierField
        v-model="value.private_v"
        :metadata="metadata.private_v"
        :disabled="!value.active"
      />
    </div>
  </div>
</div>
</template>
