import { API } from '@/utils'
import JwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state: {
    profile: null,
    jwt: null,
  },
  getters: {
    isAdmin: state => state.profile && state.profile.is_admin,
    isRoot: state => state.profile && state.profile.is_root,
  },
  mutations: {
    jwt: (state, next) => {
      if (next === 'null') next = null
      state.jwt = next
      localStorage.setItem('backoffice/jwt', next)
      if (next) {
        const profile = JwtDecode(next)
        state.profile = profile
      } else {
        state.profile = null
      }
    }
  },
  actions: {
    init: async (store) => {
      const jwt = localStorage.getItem('backoffice/jwt')
      store.commit('jwt', jwt)
    },
    login: async (store, {email, password}) => {
      let data = null
      try {
        const req = await API().post('/login', {email, password})
        data = req.data
      } catch (err) {
        console.log(err)
        data = null
      }
      if (data.success && data.jwt) {
        store.commit('jwt', data.jwt)
        return true
      }
      return false
    },
    logout: async (store) => {
      store.commit('jwt', null)
    }
  }
}
