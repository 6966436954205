import { createApp } from 'vue'
import vClickOutside from 'click-outside-vue3'
import App from './App.vue'
import router from './router'
import store from './store'

router.beforeEach(async (to) => {
  if (!store.state.initialized) {
    store.commit('ui/loading', true)
    await store.dispatch('init')
    store.commit('ui/loading', false)
  }
  if (to.meta) {
    let ok = true
    if (to.meta.admin && !store.getters['auth/isAdmin']) ok = false
    if (to.meta.root && !store.getters['auth/isRoot']) ok = false
    if (!ok) return { name: 'login' }
  } else {
    console.log('??', to.meta)
    if (store.getters['auth/isAdmin'] || store.getters['auth/isRoot']) {
      console.log('logged already')
      return { name: 'home' }
    }
  }
})

createApp(App)
  .use(store)
  .use(router)
  .use(vClickOutside)
  .mixin({
    computed: {
      $uiBusy: () => store.state.ui.busy,
      $isAdmin: () => store.getters['auth/isAdmin'],
      $isRoot: () => store.getters['auth/isRoot']
    }
  })
  .mount('#app')
