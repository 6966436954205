<script>
export default {
  name: 'PopupMessage',
  props: ['type', 'message']
}
</script>

<template>
<div class="toast show" :class="`text-bg-${type}`">
  <div class="toast-body">{{message}}</div>
</div>
</template>
