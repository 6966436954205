import { createStore } from 'vuex'
import auth from '@/store/auth'
import ui from '@/store/ui'
import tables from '@/store/tables'
import { API } from '@/utils'

export default createStore({
  modules: {
    auth,
    ui,
    tables
  },
  state: {
    initialized: false,
    stats: {
      registered: 0,
      tidex: 0,
      ntidex: 0,
      tshirt: 0,
      ntshirt: 0,
    },
    tstats: {}
  },
  mutations: {
    stats: (state, next) => { state.stats = next },
    ustats: (state, next) => { state.ustats = next },
    tstats: (state, next) => { state.tstats = next },
    init: (state) => { state.initialized = true }
  },
  actions: {
    init: async (store) => {
      await store.dispatch('auth/init')
      store.commit('init')
    },
    loadUserStats: async (store, data) => {
      const jwt = store.state.auth.jwt
       try {
        const req = await API(jwt).get('/ustat', {
          params: data
        })
        if (req.data && req.data.success) {
          return req.data
        }
      } catch (err) {
        console.log('load ustats error', err)
      }
      return null
    },
    loadUStats: async (store, data) => {
      const jwt = store.state.auth.jwt
       try {
        const req = await API(jwt).get('/ustats', {
          params: data
        })
        if (req.data && req.data.success) {
          store.commit('stats', req.data)
          return req.data
        }
      } catch (err) {
        console.log('load ustats error', err)
      }
      return null
    },
    loadTStats: async (store) => {
      const jwt = store.state.auth.jwt
       try {
        const req = await API(jwt).get('/tstats')
        if (req.data && req.data.success) {
          store.commit('tstats', req.data)
          return req.data
        }
      } catch (err) {
        console.log('load tstats error', err)
      }
      return null
    }
  },
})
