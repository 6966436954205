import axios from 'axios'

export const API = (jwt = null, upload = false) => {
  const url = process.env.VUE_APP_BACKEND_API
  if (!jwt)
    return axios.create({
      baseURL: url
    })
  const headers = {
    Authorization: `Bearer ${jwt}`
  }
  if (upload)
    headers['Content-Type'] = 'multipart/form-data'
  return axios.create({
    baseURL: url,
    headers
  })
}

export const waitMs = (ms, sec = false) => new Promise(resolve => setTimeout(resolve, (sec ? ms * 1000 : ms) ))
