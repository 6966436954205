<script>
import TablePage from '@/components/TablePage'
export default {
  name: 'NewsView',
  components: {
    TablePage
  }
}
</script>

<template>
<TablePage
  title="News"
  data-key="news"
  :canAdd="true"
  add-button="Add"
/>
</template>
