export default {
  namespaced: true,
  state: {
    busy: false,
    loading: false,
    popups: [],
    dialogConfirm: {
      resolve: null,
      title: null,
      message: null
    },
    mmenu: false
  },
  mutations: {
    busy: (state, next) => { state.busy = next },
    loading: (state, next) => { state.loading = next },
    push: (state, next) => { state.popups.push(next) },
    shift: (state) => { state.popups.shift() },
    confirm: (state, dialog) => { state.dialogConfirm = dialog },
    completeConfirm: (state, res) => {
      if (!state.dialogConfirm || !state.dialogConfirm.resolve) return
      state.dialogConfirm.resolve(res)
      state.dialogConfirm = {
        resolve: null,
        title: null,
        message: null
      }
    },
    togglemm: (state) => { state.mmenu = !state.mmenu }
  },
  actions: {
    popup: (store, data) => {
      const ms = data.timeout || 4000
      store.commit('push', data)
      setTimeout(() => {
        store.commit('shift')
      }, ms)
    },
    confirm: (store, dialog) => new Promise(resolve => {
      dialog.resolve = resolve
      store.commit('confirm', dialog)
    })
  }
}
