<script>
export default {
  name: 'ModifierField',
  props: ['metadata','modelValue', 'disabled'],
  emits: ['update:modelValue'],
  data() {
    const ret = {
      value: {
        operation: '+',
        modifier: 1,
        dimension: '%',
        use_multiplier: false
      }
    }
    if (this.modelValue) ret.value = this.modelValue
    return ret
  },
  watch: {
    modelValue(next) {
      if (next)
        this.value = next
    }
  },
  created() {
    if (!this.modelValue)
      setTimeout(() => {
        this.$emit('update:modelValue', this.value)
      }, 0)
  },
  methods: {
    onChanged() {
      this.$emit('update:modelValue', this.value)
    }
  }
}
</script>

<template>
<div class="col-12 col-sm-6 p-2">
  <div class="label">
    <label class="text-muted">{{metadata?.label}}</label>
  </div>
  <div v-if="!!value" class="input-group">
    <select
      class="form-select"
      :disabled="$uiBusy || disabled"
      v-model="value.operation"
    >
      <option value="+">Plus</option>
      <option value="-">Minus</option>
      <option value="*">Multiple</option>
      <option value="/">Divide</option>
      <option value="=">Set</option>
    </select>
    <input
      type="number"
      :disabled="$uiBusy || disabled"
      class="form-control"
      @input="onChanged()"
      v-model="value.modifier"
    />
    <select
      class="form-select"
      :disabled="$uiBusy || disabled"
      v-model="value.dimension"
    >
      <option value="%">Percent (%)</option>
      <option value="i">Number</option>
    </select>
    <div class="input-group-text">
      <span>Use multiplier</span>
      <input
        type="checkbox"
        class="form-check-input mt-0 ms-2"
        :disabled="$uiBusy || disabled"
        v-model="value.use_multiplier"
      />
    </div>
  </div>
  <div class="mb-2" v-if="metadata?.help">
    <small class="text-muted">{{metadata.help}}</small>
  </div>
</div>
</template>
