<script>
export default {
  name: 'TextareaField',
  props: ['metadata','modelValue', 'disabled'],
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue
    }
  },
  watch: {
    modelValue(next) {
      if (this.value !== next)
        this.value = next
    }
  },
  methods: {
    onChanged() {
      this.$emit('update:modelValue', this.value)
    }
  }
}
</script>

<template>
<div class="col-12 p-2">
  <div class="label">
    <label class="text-muted">{{metadata?.label}}</label>
  </div>
  <div class="field">
    <textarea
      :disabled="$uiBusy || disabled"
      class="form-control"
      @input="onChanged()"
      v-model="value"
    />
  </div>
  <div class="mb-2" v-if="metadata?.help">
    <small class="text-muted">{{metadata.help}}</small>
  </div>
</div>
</template>
