<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import TopBar from '@/components/TopBar'
import SideBar from '@/components/SideBar'
import PopupsContainer from '@/components/ui/PopupsContainer'
import ConfirmDialog from '@/components/ui/ConfirmDialog'

export default {
  name: 'App',
  components: {
    TopBar,
    SideBar,
    ConfirmDialog,
    PopupsContainer,
  }
}
</script>

<template>
  <ConfirmDialog/>
  <TopBar/>
  <div class="app-page d-flex vh-100">
    <PopupsContainer/>
    <SideBar/>
    <div class="app-content flex-fill p-2">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  min-height: 100vh;
  background: #F3F3F3;
}

.app-content {
  overflow-y: scroll;
}

.app-page {
  padding-top: 72px;
  min-height: 100%;
}
</style>
