<script>
export default {
  name: 'ViewPage',
  props: ['title'],
}
</script>

<template>
<div class="table-form bg-white m-3">
  <div class="table-page-header d-flex p-3 border-bottom border-gray">
    <h2 class="flex-fill mb-0">{{title}}</h2>
  </div>
  <div class="form-body py-3 px-4">
    <slot/>
  </div>
</div>
</template>
