<script>

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      err: false
    }
  },
  methods: {
    async login() {
      this.$store.commit('ui/busy', true)
      this.err = false
      let ok = await this.$store.dispatch('auth/login', {
        email: this.email,
        password: this.password
      })
      this.$store.commit('ui/busy', false)
      if (ok) {
        this.$router.push({name: 'home'})
      } else {
        this.err = true
      }
    }
  }
}
</script>

<template>
  <div class="text-center">
    <div class="card col-12 col-md-4 col-lg-3 m-auto">
      <div class="card-body">
        <h5 class="card-title">Login</h5>
        <form @submit.prevent.stop="login()">
          <div class="mb-3">
            <label for="email-field" class="form-label">Email</label>
            <input :disabled="$uiBusy" type="email" class="form-control" id="email-field" v-model="email">
          </div>
          <div class="mb-3">
            <label for="password-field" class="form-label">Password</label>
            <input :disabled="$uiBusy" type="password" class="form-control" id="password-field" v-model="password">
          </div>
          <div v-if="err" class="mb-3">
            <div class="text-danger">Failed</div>
          </div>
          <div class="mb-3">
            <button :disabled="$uiBusy" class="btn btn-primary" type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
