<script>
export default {
  name: 'StatsBlock',
  components: {
  },
  props: ['statNumber', 'statPercent', 'label']
}
</script>

<template>
<div class="p-1 col-12 col-sm-4 col-md-3">
  <div class="py-3 px-4 border border-grey">
    <div class="numbers d-flex">
      <div class="main me-3 text-bold">{{statNumber}}</div>
      <div v-if="typeof statPercent !== 'undefined'" class="text-muted percent">{{statPercent}}%</div>
    </div>
    <div class="labekl">{{label}}</div>
  </div>
</div>
</template>

<style scoped>
.main {
  font-size: 300%;
}

.percent {
  font-size: 250%;
}
</style>
