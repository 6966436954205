<script>
import PopupMessage from '@/components/ui/PopupMessage'
export default {
  name: 'PopupsContainer',
  components: {
    PopupMessage
  }
}
</script>

<template>
<div class="toast-container top-0 end-0 pe-4">
  <PopupMessage
    v-for="popup, pi of $store.state.ui.popups"
    :key="pi"
    :type="popup.type"
    :message="popup.message"
  />
</div>
</template>

<style scoped>
.toast-container {
  padding-top: 75px;
}
</style>
