<script>
import FormPage from '@/components/FormPage'
import TextField from '@/components/fields/TextField'
import UploadImageField from '@/components/fields/UploadImageField'

import { API } from '@/utils'
export default { name: 'LanguageForm',
  components: {
    FormPage,
    UploadImageField,
    TextField
  },
  data() {
    return {
      isNew: true,
      formFields: {
        name: {
          type: 'text',
          label: 'Name',
        }, code: {
          type: 'text',
          label: 'Code'
        },
        icon: {
          type: 'image',
          label: 'Icon',
          table: 'languages',
          field: 'icon',
          path: 'languages'
        },
        translated: {
          type: 'file',
          label: 'Localization File',
          table: 'languages',
          field: 'translated',
          path: 'languages'
        },
      },
      formValue: {
        name: '',
        code: '',
        icon: false,
        translated: false,
        active: false
      }
    }
  },
  computed: {
    formTitle() {
      return this.isNew ? 'Add Language' : 'Edit Language'
    }
  },
  async created() {
    this.$store.commit('ui/busy', true)
    console.log(this.formValue)
    if (this.$route.name === 'languagesAdd') {
      this.$store.commit('ui/busy', false)
      return
    }
    const nid = this.$route.params.lid
    const el = await this.$store.dispatch('tables/loadOne', {table: 'languages', id: nid})
    if (!el) {
      this.$store.dispatch('ui/popup', {type: 'warn', message: 'Not Found!'})
      setTimeout(() => {
        this.$store.commit('ui/busy', false)
        this.formCancel()
      }, 0)
      return
    }
    this.isNew = false
    this.formValue = el.data
    this.$store.commit('ui/busy', false)
  },
  methods: {
    async formSave() {
      this.$store.commit('ui/busy', true)
      const saveData = {
        name: this.formValue.name,
        code: this.formValue.code,
        active: this.formValue.active,
        icon: !!this.formValue.icon,
        translated: !!this.formValue.translated,
      }
      if (this.formValue.language_id)
        saveData.language_id = this.formValue.language_id
      const res = await this.$store.dispatch('tables/saveOne', {
        table: 'languages',
        data: saveData,
        isNew: this.isNew
      })
      this.$store.commit('ui/busy', false)
      if (res.success) {
        /*
        if (!this.formValue.icon && !this.formValue.translated) {
          this.$store.dispatch('ui/popup', {type: 'success', message: 'Saved!'})
          this.$router.push({name: 'languages'})
          return
        }
        */
        // upload files
        const fd = new FormData()
        if (!!this.formValue.icon && typeof this.formValue.icon === 'string')
          fd.append('icon', this.formValue.icon)
        if (!!this.formValue.translated && typeof this.formValue.translated !== 'boolean')
          fd.append('localization', this.formValue.translated)
        fd.append('lid', res.lid)
        console.log(fd)
        let upRes = null
        try {
          const jwt = this.$store.state.auth.jwt
          upRes = await API(jwt, true).post('/localization', fd)
        } catch (err) {
          console.error('upload error', err)
          upRes = null
        }
        if (!upRes || !upRes.data || !upRes.data.success) {
          this.$store.dispatch('ui/popup', {type: 'danger', message: 'Files upload failed!'})
          return
        }
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Saved!'})
        this.$router.push({name: 'languages'})
      } else {
        this.$store.dispatch('ui/popup', {type: 'danger', message: `Error: ${res.error}`})
      }
    },
    formCancel() {
      // this.$store.dispatch('ui/popup', {type: 'light', message: 'Canceled'})
      this.$router.push({name: 'languages'})
    },
    async formDelete() {
      const ok = await this.$store.dispatch('ui/confirm', {
        title: 'Delete Language?',
        message: 'Information will be lost! Are you sure?'
      })
      if (!ok) return
      this.$store.commit('ui/busy', true)
      const delRes = await this.$store.dispatch('tables/deleteOne', {
        table: 'languages',
        id: this.formValue.language_id
      })
      this.$store.commit('ui/busy', false)
      if (delRes.success) {
        this.$store.dispatch('ui/popup', {type: 'success', message: 'Deleted', timeout: 2000})
        this.$router.push({name: 'languages'})
        return
      }
      this.$store.dispatch('ui/popup', {type: 'danger', message: 'Failed. Error: ' + delRes.error})
    }
  }
}
</script>

<template>
<FormPage
  :title="formTitle"
  :can-delete="!isNew"
  @clickSave="formSave()"
  @clickCancel="formCancel()"
  @clickDelete="formDelete()"
>
  <div class="container">
    <div class="row">
      <TextField
        v-model="formValue.name"
        :metadata="formFields.name"
      />
      <TextField
        v-model="formValue.code"
        :metadata="formFields.code"
      />
    </div>
    <div class="row"><div class="col"><h3>Icon</h3></div></div>
    <div class="row">
      <UploadImageField
        v-model="formValue.icon"
        :metadata="formFields.icon"
        :owner-id="formValue.language_id || null"
        data-key="languages"
      />
    </div>
    <div class="row"><div class="col"><h3>Localization File</h3></div></div>
    <div class="row">
      <UploadImageField
        v-model="formValue.translated"
        :metadata="formFields.translated"
        :owner-id="formValue.language_id || null"
        data-key="languages"
        :is-file="true"
      />
    </div>
    <div class="row"><div class="col"><h3>Active</h3></div></div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" class="form-check-input" id="language-active" v-model="formValue.active"/>
        <label for="language-active" class="ms-2">Active</label>
      </div>
    </div>
  </div>
</FormPage>
</template>
