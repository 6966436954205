<script>
export default {
  name: 'DropdownField',
  props: ['metadata','modelValue', 'disabled'],
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue
    }
  },
  watch: {
    modelValue(next) {
      if (this.value !== next)
        this.value = next
    }
  },
  methods: {
    onChanged() {
      this.$emit('update:modelValue', this.value)
    }
  }
}
</script>

<template>
<div class="col-12 col-sm-6 p-2">
  <div class="label">
    <label class="text-muted">{{metadata?.label}}</label>
  </div>
  <div class="field">
    <select
      class="form-select"
      :disabled="$uiBusy || disabled"
      @change="onChanged()"
      v-model="value"
    >
      <option
        v-for="opt, opti of metadata.list"
        :key="opti"
        :value="opt.key"
      >
        {{opt.label}}
      </option>
    </select>
  </div>
</div>
</template>
